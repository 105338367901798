import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CssBaseline, Container } from '@material-ui/core';
import Header from "./components/header";
import Footer from "./components/footer";
import User from "./components/user";
import Home from "./components/home";
import UsersGroups from "./components/usersgroups";
import Group from "./components/group";
import withAuthProvider, { AuthComponentProps } from "./AuthProvider";
import UserPhoto from './components/userphoto';

const NoMatch = () => (
    <div>
        <div className="container">
            <div className="row row-buffer">
                <div className="col-md">
                    <div className="alert alert-warning" role="alert">
                        404 - Page not found!
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const UnAuthenticated = () => (
    <div>
        <div className="container">
            <div className="row row-buffer">
                <div className="col-md">
                    <div className="alert alert-warning" role="alert">
                        401 - You have not been authenticated!
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const UnAuthorised = () => (
    <div>
        <div className="container">
            <div className="row row-buffer">
                <div className="col-md">
                    <div className="alert alert-warning" role="alert">
                        403 - You have not been authorised!
                    </div>
                </div>
            </div>
        </div>
    </div>
);

const signOut = () => (
    <div>
        <div className="container">
            <div className="row row-buffer">
                <div className="col-md">
                    <div className="alert alert-warning" role="alert">
                        <h4>You are signed out successfully.</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

class App extends React.Component<AuthComponentProps> {

    
    public render() {
        if (this.props.isAuthorised && this.props.hasLoaded) {
            return (
                <>
                    <CssBaseline />
                    <Router>
                        <Header auth={this.props} />
                        <Container maxWidth="lg">
                            <Switch>
                                <Route path="/" exact component={() => (<Home auth={this.props} />)} />
                                <Route path="/user/:query" exact component={() => (<User auth={this.props} />)} />
                                <Route path="/user/:query/groups" exact component={() => (<UsersGroups auth={this.props} />)} />
                                <Route path="/group/:query" exact component={() => (<Group auth={this.props} />)} />
                                <Route path="/user/:query/photo" component={() => (<UserPhoto auth={this.props} />)} />
                                <Route component={NoMatch} />
                            </Switch>
                        </Container>
                        <Footer />
                    </Router>
                </>
            );
        }
        else if (this.props.hasLoaded) {
            return (<UnAuthorised />);
        }
        else {
            return <> </>
        }
    }
}

export default withAuthProvider(App);
