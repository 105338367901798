import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthComponentProps } from '../AuthProvider';
import { msalRequest } from '../config';
import { instance as api, setAuthHeader } from '../api';
import ProfilePhoto from '../Models/profilePhoto';
import {
    makeStyles,
    Grid,
    Card,
    CardHeader,
    CardMedia,
    Box,
    Paper,
} from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing(2)
    },
    cardGrid: {
        paddingTop: theme.spacing(8),
        paddingBottom: theme.spacing(8),
    }
}))
interface Props extends RouteComponentProps<{ query: string }> {
    auth: AuthComponentProps;
}
const Photo: React.FC<Props> = (props: Props) => {
    const [query] = useState<string>(props.match.params.query);
    const [photos, setPhotos] = useState<ProfilePhoto[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    useEffect(() => {
        let isMounted = true;
        let reg = /^\d+$/;
        setIsLoading(true);
        const getUserPhotos = async (endpoint: string) => {
            const token = await props.auth.getAccessToken(msalRequest.scopes);
            setAuthHeader(token)
            await api
                .get<ProfilePhoto[]>(`${endpoint}`)
                .then((resp) => {
                    console.log(resp);
                    if (isMounted) {
                        setPhotos(resp.data);
                        setIsLoading(false);
                    }
                });
        }
        if (reg.test(query)) {
            getUserPhotos(`GetUserPhotosByStaffId/${query}`);
        } else {
            getUserPhotos(`GetUserPhotosByUpn/${encodeURI(query)}`);
        }
        return () => {
            isMounted = false;
        };
    }, [query, props]);
    return (
        <Paper>
            {isLoading ?
                <>
                    <Container className={classes.cardGrid} maxWidth="md" >
                        <Grid container spacing={2}>
                            {[1, 2, 3].map(skeleton => (
                                <Grid item xs={12} sm={6} md={4} key={skeleton}>
                                    <Card>
                                        <Box mt={3.5} mb={3.5} ml={2}><Skeleton variant="rect" height={40} width={Math.random() * (250 - 100) + 100} /></Box>
                                        <Box><Skeleton variant="rect" height={300} width={300} /></Box>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Container>
                </>
                :
                <>
                    <Container className={classes.cardGrid} maxWidth="md" >
                        <Grid container spacing={2}>
                            {photos.map(photo => {
                                    return (
                                      <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        key={photos.indexOf(photo)}
                                      >
                                        <Card>
                                          <CardHeader
                                            title={photo.source}
                                            component="h5"
                                          />
                                          <CardMedia
                                            component="img"
                                            height="300"
                                            src={
                                              photo.photo !== ""
                                                ? `data:image/jpeg;base64, ${photo.photo}`
                                                : "/images/person.png"
                                            }
                                            title={photo.photo !== ""
                                                ? "Profile Photo"
                                                : "No Photo Found"
                                            }
                                          />
                                        </Card>
                                      </Grid>
                                    );
                            })}
                        </Grid>
                    </Container>
                </>
            }
        </Paper>

    );
}
export default withRouter(Photo);