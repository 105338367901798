import React, { useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import { fade, makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { instance as api, setAuthHeader, ApiCancelToken } from '../api';
import SearchModel from '../Models/searchModel';
import { AuthComponentProps } from "../AuthProvider";
import { msalRequest } from '../config';
import { useHistory } from 'react-router-dom';
import { CancelTokenSource } from 'axios';

const useStyles = makeStyles((theme: Theme) => createStyles({

    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '50ch',
        },
    }
}));
interface Props {
    auth: AuthComponentProps;
}
const Search: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const history = useHistory();
    const [searchResult, setSearchResult] = useState<SearchModel[]>([]);
    const [value, setValue] = useState<string | null>('');
    let cancelToken: CancelTokenSource;
    const [isLoading, setIsLoading] = useState(true);
    const options = searchResult.map((option) => {
        return {
            category: option.type,
            ...option,
        };
    });
    const onChange = (event: any, values: any) => {
        setValue(values.displayName);
        if (values) {
            return values.category === 'User' ? history.push(`/user/${values.upn}`)
                : history.push(`/group/${values.id}`);
        }
        return "";
    }
    const onInputChange = async (value: string) => {
        if (value === '')
            value = null;
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel();
        }
        //Save the cancel token for the current request
        cancelToken = ApiCancelToken.source();
        const token = await props.auth.getAccessToken(msalRequest.scopes);
        setIsLoading(true);
        setAuthHeader(token);
        await api.get<SearchModel[]>(`SearchUserGroup/${value}`, { cancelToken: cancelToken.token })
            .then((resp) => {
                var result = resp.data
                setIsLoading(false);
                setSearchResult(result);
            }).catch(error => {
            });
    }
    if (props.auth.isAuthenticated) {
        return (<div className={classes.search}>
            <Autocomplete
                id="user-group-select"
                key={value}
                options={options}
                groupBy={(option) => option.category}
                size="small"
                autoHighlight
                getOptionLabel={(option: any) => (option.displayName + option.staffId + option.upn)}
                renderOption={(option) => {
                    if (option.type === 'Group') {
                        return (<React.Fragment>
                            {option.displayName} {option.onPremisesSyncEnabled === true ? '' : '(Cloud only)'}
                        </React.Fragment>);
                    }
                    else {
                        return (<React.Fragment>
                            {option.displayName} ({option.staffId}/{option.upn})
                        </React.Fragment>);
                    }
                }}
                onChange={onChange}
                renderInput={(params) => {
                    return (<>
                        <div className={classes.searchIcon}>
                            <SearchIcon />
                        </div>
                        <InputBase
                            ref={params.InputProps.ref}
                            classes={{
                                root: classes.inputRoot,
                                input: classes.inputInput,
                            }}
                            placeholder="Type Name, StaffID, UPN or Group Name"
                            id="usergroup"
                            onChange={(e) => onInputChange(e.target.value)}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password'
                            }}
                        />
                    </>)
                }}
            />
        </div>
        )
    }
    else return (<div> not authorised </div>);
}
export default Search;

