import React from 'react';
import { AuthComponentProps } from "../AuthProvider";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

interface Props extends RouteComponentProps<{ query: string }> {
  auth: AuthComponentProps;
}


const sources = [
  {
    title: "Microsoft Graph",
    description:
      "Using Microsoft Graph to get User' and 'Group' properties. 'Users' and 'Groups' are the representation of Arup's Azure Active Directory in Microsoft Graph",
    image: "/images/azure-active-directory.png",
    buttonLink: "https://developer.microsoft.com/en-us/graph/",
    buttonVariant: "outlined",
  },
  {
    title: "Active Directory",
    description:
      "Using Windows Active Directory to get 'User' and 'Group' properties.",
    image: "/images/active-directory-logo.png",
    buttonLink:
      "https://docs.microsoft.com/en-us/windows-server/identity/ad-ds/get-started/virtual-dc/active-directory-domain-services-overview",
    buttonVariant: "outlined",
  },
  {
    title: "Arup Data Services (ADS)",
    description: "Using Arup Data Services (ADS) to get 'Staff' properties.",
    image: "/images/arup-data-services.jpg",
    buttonLink:
      "https://arup.sharepoint.com/sites/essentials-corporate-services/information-technology/aisportal/Pages/Home-Page.aspx",
    buttonVariant: "outlined",
  },
];

const Home: React.FC<Props> = (props: Props) => {
  const classes = useStyles();
    return (
         <main>
          <div className={classes.heroContent}>
            <Container maxWidth="sm">
              <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
                Arup Identity Tools
              </Typography>
              <Typography variant="h5" align="center" color="textSecondary" paragraph>
                A utility used by Digital Technology staff to compare data between Microsoft Graph, Active Directory and Arup Data Services (ADS).
              </Typography>
            </Container>
          </div>
          <Container className={classes.cardGrid} maxWidth="md">
            {/* End hero unit */}
            <Grid container spacing={4}>
              {sources.map((source) => (
                <Grid item key={sources.indexOf(source)} xs={12} sm={6} md={4}>
                  <Card className={classes.card}>
                    <CardMedia
                      className={classes.cardMedia}
                      image={source.image}
                      title="Image title"
                    />
                    <CardContent className={classes.cardContent}>
                      <Typography gutterBottom variant="h5" component="h2">
                        {source.title}
                      </Typography>
                      <Typography>
                        {source.description}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button size="small" color="primary" target="_blank" href={source.buttonLink}>
                        View
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>
          </main>
    );
}
export default withRouter(Home);