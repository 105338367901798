import React, { useEffect, useState, useRef, useCallback, } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { instance as api, setAuthHeader } from '../api';
import {
    Grid,
    Table,
    TableHeaderRow,
    Toolbar,
    ExportPanel,

} from "@devexpress/dx-react-grid-material-ui";
import { GridExporter } from '@devexpress/dx-react-grid-export';
import { Box, Paper } from '@material-ui/core';
import { UsersGroupsRows, UsersGroupsColumns } from "../Models/usersGroupsData";
import { BooleanTypeProvider, GroupLinkTypeProvider, HeaderCellComponent } from "../Utilities/TableUtilities";
import { TableSkeleton } from "../Utilities/Skeletons";
import { saveAs } from 'file-saver';
import ExcelJS from "exceljs";
import { AuthComponentProps } from "../AuthProvider";
import { msalRequest } from '../config';

interface Props extends RouteComponentProps<{ query: string }> {
    auth: AuthComponentProps;
}

const onSave = (workbook: ExcelJS.Workbook) => {
    workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'DataGrid.xlsx');
    });
};

const UsersGroupsInfo: React.FC<Props> = (props: Props) => {
    const [rows, setRows] = useState<UsersGroupsRows[]>([]);
    const [query] = useState<string>(props.match.params.query);
    const [isLoading, setIsLoading] = useState(false);
    const columns: UsersGroupsColumns[] = [
        { name: "displayName", title: "Display Name" },
        { name: "description", title: "Description" },
        { name: "onPremisesSyncEnabled", title: "On premise?" },
    ];
    const [booleanColumns] = useState<string[]>(["onPremisesSyncEnabled"]);
    const [groupLinkColumns] = useState<string[]>(["displayName"]);
    const exporterRef = useRef(null);

    const startExport = useCallback((options) => {
        if (null !== exporterRef.current) {
            exporterRef.current.exportGrid(options);
        }
    }, [exporterRef]);

    useEffect(() => {
        let isMounted = true;
        let reg = /^\d+$/;
        const getUsersGroups = async (endpoint:string) => {
            setIsLoading(true);
            const token = await props.auth.getAccessToken(msalRequest.scopes);
            setAuthHeader(token)
            await api
                .get<UsersGroupsRows[]>(`${endpoint}`)
                .then((resp) => {
                    if (isMounted) {
                        setRows(resp.data);
                        setIsLoading(false);
                    }
                }).catch(err => console.log(err));
        }
        if (reg.test(query)) {
            getUsersGroups(`GetGroupsForUserByStaffId/${query}`);
        } else {
            getUsersGroups(`GetGroupsForUser/${encodeURI(query)}`);
        }
        return () => {
            isMounted = false;
        };
    }, [query, props]);

    return (
        <section>
            <Paper>
                {isLoading ? (
                    <Box pt={1}>
                        <TableSkeleton />
                    </Box>
                ) : (
                        <>
                            <Grid rows={rows} columns={columns}>
                                <BooleanTypeProvider for={booleanColumns} />
                                <GroupLinkTypeProvider for={groupLinkColumns} />
                                <Table />
                                <TableHeaderRow cellComponent={HeaderCellComponent}/>
                                <Toolbar />
                                <ExportPanel startExport={startExport} />
                            </Grid>
                            <GridExporter ref={exporterRef} columns={columns} rows={rows} onSave={onSave} />
                        </>)}
            </Paper>
        </section>
    );
};
export default withRouter(UsersGroupsInfo);
