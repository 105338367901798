import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Paper } from '@material-ui/core';
import { AuthComponentProps } from "../AuthProvider";
import UserDetailsBar from './userdetailsbar';
import UsersGroupsInfo from './usergroupsinfo'
import UserOnPremGroupStatusBar from './useronpremgroupstatusbar'

interface Props extends RouteComponentProps<{ query: string }> {
    auth: AuthComponentProps;
}

const UsersGroups: React.FC<Props> = (props: Props) => {
    return (
        <Paper>
            <UserDetailsBar auth={props.auth} />
            <UserOnPremGroupStatusBar auth={props.auth} />
            <UsersGroupsInfo auth={props.auth} />
        </Paper>
    );
};
export default withRouter(UsersGroups);
