import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { Paper } from '@material-ui/core';
import { AuthComponentProps } from "../AuthProvider";
import UserDetailsBar from './userdetailsbar';
import Photo from './photo';

interface Props extends RouteComponentProps<{ query: string }> {
    auth: AuthComponentProps;
}

const UsersPhoto: React.FC<Props> = (props: Props) => {
    return (
        <Paper>
            <UserDetailsBar auth={props.auth} />
            <Photo auth={props.auth} />
        </Paper>
   );
};
export default withRouter(UsersPhoto);
