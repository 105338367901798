import React from "react";
import {
  DataTypeProvider,
  DataTypeProviderProps,
} from "@devexpress/dx-react-grid";
import { NavLink } from "react-router-dom";
import { TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

type BooleanFormatterProps = DataTypeProvider.ValueFormatterProps;
type GroupLinkFormatterProps = DataTypeProvider.ValueFormatterProps;

const BooleanFormatter = ({ value }: BooleanFormatterProps) => (
    <>{value ? "Yes" : "No"} </>
);

const GroupLinkFormatter = ({
  row: { id },
  value,
}: GroupLinkFormatterProps) => <NavLink to={`/group/${id}`}>{value}</NavLink>;

const BooleanTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={BooleanFormatter} {...props} />;

const GroupLinkTypeProvider: React.ComponentType<DataTypeProviderProps> = (
  props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={GroupLinkFormatter} {...props} />;

const HeaderCellComponent: React.FunctionComponent<TableHeaderRow.CellProps> = (props: TableHeaderRow.CellProps) => {
    return <TableHeaderRow.Cell {...props}
        style={{ backgroundColor: "#abb3e2", fontWeight: 700 }} />;
};

export { BooleanTypeProvider, GroupLinkTypeProvider, HeaderCellComponent}