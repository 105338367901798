"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.config = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID,
        authority: "https://login.microsoftonline.com/4ae48b41-0137-4599-8661-fc641fe77bea",
        redirectUri: process.env.REACT_APP_REDIRECT_URI
    },
    cache: {
        cacheLocation: "sessionStorage",
        storeAuthStateInCookie: true
    },
    scopes: [
        'user.read',
    ]
};
exports.msalRequest = {
    scopes: [
        `${process.env.REACT_APP_API_ID}/.default`
    ]
}
exports.aadEndpoints = 
    [
        {
        title: 'License',
        link: 'https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Licenses/userId/'
    },
    {
        title: 'Sign-in activity',
        link: `https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/SignIns/userId/`,
    },
    {
        title: 'Registered Devices',
        link: `https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Devices/userId/`,
    },
    {
        title: 'Applications',
        link: `https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/AssignedApplications/userId/`,
    },
    {
        title: 'Audit logs',
        link: `https://portal.azure.com/#blade/Microsoft_AAD_IAM/UserDetailsMenuBlade/Audit/userId/`
    }
]
