import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, CircularProgress, makeStyles, Theme, createStyles } from '@material-ui/core';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            '& > * + *': {
                marginLeft: theme.spacing(10),
            },
        },
    }),
);

export interface IDialogProps {
    title: string;
    open: boolean;
}
const ProgressDialog = (props: IDialogProps) => {
    const classes = useStyles();
    const { title, open } = props;
    return (
        <Dialog
            open={open}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog" style={{fontSize:"5px"}}>{title}</DialogTitle>
            <DialogContent>
                <Box className={classes.root} alignItems="center" display="flex">
                    <CircularProgress size={20} style={{ marginLeft: "45%" }} />
                </Box>
            </DialogContent>
        </Dialog>
    );
};
export default ProgressDialog;