import axios from 'axios';

const instance =  axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});
const ApiCancelToken = axios.CancelToken;
 const setAuthHeader = (token: string) => {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}
export { instance, setAuthHeader, ApiCancelToken };

//export default instance;