import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthComponentProps } from '../AuthProvider';
import { msalRequest } from '../config';
import { instance as api, setAuthHeader } from '../api';
import Typography from '@material-ui/core/Typography';
import { Box, makeStyles } from '@material-ui/core';
import { GroupCountSkeleton } from '../Utilities/Skeletons';

const maxAdGroupCount = 1010;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    status: {
        color: "green"
    },
    divbase: {
        height: 20,
        borderRadius: 5,
        width: (maxAdGroupCount / 4),
        backgroundColor:'#AAA',
        display: 'flex',
        marginTop: '5px',
        marginLeft: '5px'
    },
    bar: {
        borderRadius: 5,
        backgroundColor: 'green',
        color: 'white',
        paddingLeft: '6px',
        textShadow: '1px 0 0 #000'
    },
    row: {
        display: 'flex',
        flexDirection: 'row'
    }
}));

interface Props extends RouteComponentProps<{ query: string }> {
    auth: AuthComponentProps;
}

const UserOnPremGroupStatusBar: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const [isLoading, setIsLoading] = useState(false);
    const [colour, setColor] = useState("green");
    const [groupOnPremCount, setGroupOnPremCount] = useState<number>(0);
    const query = props.location.hash ? encodeURIComponent(props.match.params.query + props.location.hash) : props.match.params.query;

    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        const getOnPremGroupCount = async (endpoint: string) => {
            const token = await props.auth.getAccessToken(msalRequest.scopes);
            setAuthHeader(token);
            await api.get<number>(`${endpoint}`)
                .then((resp) => {
                    if (isMounted) {
                        setGroupCount(resp.data);
                        setIsLoading(false);
                    }
                }).catch(err => console.log(err));
        }
        getOnPremGroupCount(`GetUserOnPremGroupCount/${query}`);
        return () => {
            isMounted = false;
        };
    }, [query, props]);

    const setGroupCount = (count: number) => {
        if (count >= 900) {
            setColor("red");
        }
        else if (count >= 800) {
            setColor("orange");
        }
        setGroupOnPremCount(count);
    }

    return (
        <>
            {isLoading ? <GroupCountSkeleton /> :
              
                    <Box pl={1}>
                        <div className={classes.row}>
                            <Typography variant="h6">
                                On-Premise Group Count :
                            </Typography>
                            <div className={classes.divbase}>
                                <div className={classes.bar} style={{ width: groupOnPremCount / 4, backgroundColor: colour }}>
                                    {groupOnPremCount}
                                </div>
                            </div>
                         </div>
                    </Box>
              
            }
        </>
    )
}
export default withRouter(UserOnPremGroupStatusBar);
