import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import HomeIcon from '@material-ui/icons/Home';
import ExitTo from '@material-ui/icons/ExitToApp';
import IconButton from '@material-ui/core/IconButton';
import { useHistory, RouteComponentProps, withRouter } from 'react-router-dom';
import Search from "./search";
import { AuthComponentProps } from "../AuthProvider";

interface Props extends RouteComponentProps<{ query: string }> {
  auth: AuthComponentProps;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 0,
        justifyContent: 'flex-start',
        marginLeft: theme.spacing(0),
    },
    typographyStyle: {
        flex: 1
    },
}));

const Header: React.FC<Props> = (props: Props) => {
//const Header = (props: any) => {
    const classes = useStyles();
    const history = useHistory();

    const handleHomeClick = (event: React.MouseEvent<HTMLElement>) => {
        history.push("/");
    };

    const signOut = (event: React.MouseEvent<HTMLElement>) => {
        props.auth.logout();
    };

    return (
        <AppBar position="relative">
            <Toolbar>
                <IconButton 
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    onClick={handleHomeClick}
                >
                    <HomeIcon />
                </IconButton>                
                <Typography variant="h6" className={classes.title} noWrap>
                    Arup Identity Tool
                </Typography>
                <Search auth={props.auth} />
                <div className={classes.root} />
                {props.auth.isAuthenticated &&
                <IconButton 
                    className={classes.menuButton}
                    color="inherit"
                    onClick={signOut}
                >
                    <ExitTo />
                </IconButton>
                }
                
            </Toolbar>
        </AppBar>
    );
}
export default withRouter(Header);
