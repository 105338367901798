import React, {
    useReducer, useState, useEffect, useMemo, useRef, useCallback
} from 'react';
import Paper from '@material-ui/core/Paper';
import {
    VirtualTableState,
    createRowCache,
    DataTypeProvider,
    DataTypeProviderProps,
} from '@devexpress/dx-react-grid';
import {
    Grid,
    VirtualTable,
    TableHeaderRow,
    Toolbar,
    ExportPanel,
    TableColumnVisibility
} from '@devexpress/dx-react-grid-material-ui';
import { GridExporter } from '@devexpress/dx-react-grid-export';
import ExcelJS from "exceljs";
import { saveAs } from 'file-saver';
import { GroupMembersRows } from '../Models/groupMembersData';
import { RouteComponentProps, withRouter, NavLink } from 'react-router-dom';
import { AuthComponentProps } from '../AuthProvider';
import { Box, Typography } from '@material-ui/core';
import { GroupSkeleton } from '../Utilities/Skeletons';
import { BooleanTypeProvider, HeaderCellComponent } from '../Utilities/TableUtilities';
import { msalRequest } from '../config';
import { instance as api, setAuthHeader } from '../api';
import ConfirmDialog from './confirmdialog';
import { GroupDetailsRows } from '../Models/groupDetails';
import ProgressDialog from './progressdialog';

interface Props extends RouteComponentProps<{ query: string }> {
    auth: AuthComponentProps;
}

type UserGroupLinkformatterProps = DataTypeProvider.ValueFormatterProps;
const UserGroupLinkFormatter = (
    {
        row: { upn, memberType, id },
        value,
    }: UserGroupLinkformatterProps
) => {
    switch (memberType) {
        case 'Group':
            return <NavLink to={`/group/${id}`}>{value}</NavLink>;
        case 'User':
            return <NavLink to={`/user/${upn}`}>{value}</NavLink>;
        default:
            return <></>;
    }
}
const UserGroupLinkTypeProvider: React.ComponentType<DataTypeProviderProps> = (
    props: DataTypeProviderProps
) => <DataTypeProvider formatterComponent={UserGroupLinkFormatter} {...props} />;

const getRowId = (row: GroupMembersRows) => row.rowId;
const VIRTUAL_PAGE_SIZE = 450;

interface IInitialState {
    rows: GroupMembersRows[];
    skip: number;
    requestedSkip: number;
    take: number;
    totalCount: number;
    loading: boolean;
    lastQuery: string;
    forceReload: boolean;
    skipToken: string
}
const initialState: IInitialState = {
    rows: [],
    skip: 0,
    requestedSkip: 0,
    take: VIRTUAL_PAGE_SIZE * 2,
    totalCount: 0,
    loading: false,
    lastQuery: '',
    forceReload: false,
    skipToken: 'start'
};

type Action = {
    readonly type: 'START_LOADING';
    readonly payload: {
        requestedSkip: number,
        take: number
    }
} | {
    readonly type: 'UPDATE_ROWS';
    readonly payload: {
        skip: number,
        rows: GroupMembersRows[],
        totalCount: number,
        skipToken: string
    }
} | {
    readonly type: 'REQUEST_ERROR';
} | {
    readonly type: 'FETCH_INIT';
} | {
    readonly type: 'UPDATE_QUERY';
    readonly payload: string;
}

function reducer(state: IInitialState, action: Action) {
    switch (action.type) {
        case 'UPDATE_ROWS':
            return {
                ...state,
                ...action.payload,
                loading: false,
            };
        case 'START_LOADING':
            return {
                ...state,
                requestedSkip: action.payload.requestedSkip,
                take: action.payload.take,
            };
        case 'REQUEST_ERROR':
            return {
                ...state,
                loading: false,
            };
        case 'FETCH_INIT':
            return {
                ...state,
                loading: true,
                forceReload: false,
            };
        case 'UPDATE_QUERY':
            return {
                ...state,
                lastQuery: action.payload,
            };
        default:
            return state;
    }
}

const Group: React.FC<Props> = (props: Props) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const exporterRef = useRef(null);
    const [displayName, setDisplayName] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [isExporting, setIsExporting] = useState(false);
    const [booleanColumns] = useState<string[]>(["onPremisesSyncEnabled"]);
    const [UserGroupLinkColumns] = useState<string[]>(["displayName"]);
    const [open, setOpen] = useState(false)
    const [columns] = useState([
        { name: 'rowId', title: '#', getCellValue: (row: GroupMembersRows) => row.rowId },
        { name: 'memberType', title: 'Type' },
        { name: 'displayName', title: 'Display Name' },
        { name: 'onPremisesSyncEnabled', title: 'On Premises' },
        { name: 'userType', title: 'User Type' },
        { name: 'upn', title: 'User Principal Name' },
        { name: 'description', title: 'Description' },
    ]);
    const defaultHiddenColumnNames: string[] = ['rowId'];
    const cache = useMemo(() => createRowCache(VIRTUAL_PAGE_SIZE), []);
    const startExport = useCallback((options) => {
        if (null !== exporterRef.current) {
            exporterRef.current.exportGrid(options);
        }
    }, [exporterRef]);
    let excelWorkbook: ExcelJS.Workbook;
    const onSave = (workbook: ExcelJS.Workbook) => {
        excelWorkbook = workbook;
        state.skipToken !== null ? setOpen(true) : save();
    };
    const handleYes = () => {
        setOpen(false);
        save();
    }
    const save = () => {
        excelWorkbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'GroupMembers.xlsx');
        });
    }
    const onSaveNested = async (workbook: ExcelJS.Workbook) => {
        workbook.xlsx.writeBuffer().then((buffer) => {
            saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'NestedGroupMembers.xlsx');
        });
        setIsExporting(false);
    }
    const menuItemComponent: React.ComponentType<ExportPanel.MenuItemProps> = (props: ExportPanel.MenuItemProps) => {
        return <>
            <ExportPanel.MenuItem {...props} text={"Export Data"} />
            <ExportPanel.MenuItem {...props} text={"Export Nested Data"} onClick={() => { if (!isExporting) getGroupMemberNested(); }} />
        </>
    };
    const updateRows = (skip: number, take: number, skipToken: string, receivedCount: number) => {
        let newTotalCount = skip + receivedCount + VIRTUAL_PAGE_SIZE
        if (receivedCount === 0) {
            newTotalCount = state.totalCount;
        }
        else if (!skipToken && receivedCount !== 0) {
            newTotalCount = skip + receivedCount;
        }
        dispatch({
            type: 'UPDATE_ROWS',
            payload: {
                skip: skip,
                rows: cache.getRows(skip, take),
                totalCount: newTotalCount,
                skipToken: skipToken
            },
        });
    };
    const getRemoteRows = (requestedSkip: number, take: number) => {
        dispatch({ type: 'START_LOADING', payload: { requestedSkip, take } });
    };
    const URL = `${api.defaults.baseURL}GetGroupMembersFlat/${props.match.params.query}`;
    const buildQueryString = () => {
        const {
            requestedSkip, take
        } = state;
        return `${URL}/${requestedSkip}/${take}`;
    };
    const getGroupMemberNested = async () => {
        const token = await props.auth.getAccessToken(msalRequest.scopes);
        setAuthHeader(token)
        setIsExporting(true);
        api.get<GroupDetailsRows>(`GetGroupMembersNested/${props.match.params.query}`)
            .then((resp: any) => {
                let groupDetails = resp.data.groupMembersDetails;
                if (groupDetails) {
                    let workbook = new ExcelJS.Workbook();
                    let worksheet = workbook.addWorksheet('NestedGroupMembers');
                    worksheet.columns = [
                        { header: 'Type', key: 'memberType' },
                        { header: 'Display Name', key: 'displayName' },
                        { header: 'On Premises', key: 'onPremisesSyncEnabled' },
                        { header: 'User Type', key: 'userType' },
                        { header: 'User Principal Name', key: 'upn' },
                        { header: 'Description', key: 'description' }
                    ]
                    worksheet.getRow(1).font = { bold: true }
                    worksheet.addRows(groupDetails.groupMembersList);
                    onSaveNested(workbook);
                }
            }).catch(err => console.log(err));
    }
    useEffect(() => {
        const {
            requestedSkip, take, lastQuery, loading, forceReload, skipToken
        } = state;
        const getGroupMembersFlat = async () => {
            const token = await props.auth.getAccessToken(msalRequest.scopes);
            setAuthHeader(token)
            const query = buildQueryString();
            if ((query !== lastQuery || forceReload) && !loading) {
                if (forceReload) {
                    cache.invalidate();
                }
                const cached = cache.getRows(requestedSkip, take);
                if (cached.length === take) {
                    updateRows(requestedSkip, take, skipToken, 0);
                } else {
                    dispatch({ type: 'FETCH_INIT' });
                    const data = { skipTokens: skipToken }
                    fetch(query, {
                        method: `post`,
                        body: JSON.stringify(data),
                        headers: new Headers({
                            'Authorization': `Bearer ${token}`
                        })
                    }).then(response => response.json())
                        .then((resp) => {
                            setDisplayName(resp.displayName);
                            cache.setRows(requestedSkip, resp.groupMembersDetails.groupMembersList);
                            const receivedCount = resp.groupMembersDetails.groupMembersList.length;
                            updateRows(requestedSkip, take, resp.groupMembersDetails.skipToken, receivedCount);
                            setIsLoading(false);
                        }).catch(() => dispatch({ type: 'REQUEST_ERROR' }));
                }
                dispatch({ type: 'UPDATE_QUERY', payload: query });
            }
        }
        getGroupMembersFlat();
    });

    const {
        rows, skip, totalCount, loading
    } = state;
    let exportRows = cache.getRows(0, totalCount);
    return (
        <Paper>{
            (isLoading) ?
                <>
                    <GroupSkeleton />
                </>
                :
                (
                    <>
                        <Box p={2}>
                            <Typography variant="h4">
                                {displayName}
                            </Typography>
                        </Box>
                        <Grid
                            rows={rows}
                            columns={columns}
                            getRowId={getRowId}
                        >
                            <BooleanTypeProvider for={booleanColumns} />
                            <UserGroupLinkTypeProvider for={UserGroupLinkColumns} />
                            <VirtualTableState
                                loading={loading}
                                totalRowCount={totalCount}
                                pageSize={VIRTUAL_PAGE_SIZE}
                                skip={skip}
                                getRows={getRemoteRows}
                                infiniteScrolling={false}
                            />
                            <VirtualTable height={800} />
                            <TableHeaderRow cellComponent={HeaderCellComponent} />
                            <TableColumnVisibility
                                defaultHiddenColumnNames={defaultHiddenColumnNames} />
                            <Toolbar />
                            <ExportPanel startExport={startExport} menuItemComponent={menuItemComponent} />
                        </Grid>
                        <GridExporter ref={exporterRef} columns={columns} rows={exportRows} onSave={onSave} />
                    </>
                )}
            <ConfirmDialog
                title="Continue export?"
                open={open}
                setOpen={setOpen}
                onConfirm={handleYes}
            >
                This will export partial data.
                To export all data scroll to the bottom of the page and try again.
                Do you wish to continue to download partial data?
            </ConfirmDialog>
            <ProgressDialog
                title="Data is exporting..."
                open={isExporting}
            />
        </Paper>
    );
};
export default withRouter(Group);
