import React from 'react';
import { Box, Container, Typography } from '@material-ui/core';

const Footer = () => {
    return (
        <footer>
            <Container maxWidth="lg">
                <Box m={3}>
                    <Typography variant="caption" display="block" align="center">
                        @Arup 2020
                    </Typography>
                </Box>
            </Container>
        </footer>
    );
}

export default Footer;