import React from 'react';
import { RouteComponentProps, withRouter} from 'react-router-dom';
import { Paper} from '@material-ui/core';
import { AuthComponentProps } from "../AuthProvider";
import UserDetailsBar from './userdetailsbar';
import UserInfo  from './userinfo'

interface Props extends RouteComponentProps<{ query: string }> {
    auth: AuthComponentProps;
}

const User: React.FC<Props> = (props: Props) => {
    if (props.auth.isAuthenticated) {
        return (
            <Paper>
                <UserDetailsBar auth={props.auth} />
                <UserInfo auth={props.auth} />
            </Paper>
        );
    }
    else {
        return (<div> not authorised </div>);
    }
}

export default withRouter(User);
