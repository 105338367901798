import React from "react";
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import { Box } from '@material-ui/core';

const UserDetailsSkeleton = () => {
    return (
        <>
            <Box display="flex" p={2} pl={1.4}>
                <Box pt={1.5} pr={1}>
                    <Skeleton variant="circle" height={16} width={16} />
                </Box>
                <Box>
                    <Skeleton variant="rect" height={45} width={250} />
                </Box>
            </Box>
            <Box p={1}>
                <Grid container item xs={12}>
                    <Grid item xs={3}>
                        <Skeleton variant="rect" height={35} />
                    </Grid>
                    <Grid item xs={2}>  </Grid>
                    <Grid item xs={7}>
                        <Skeleton variant="rect" height={35} />
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

const GroupSkeleton = () => {
    return (
        <>
            <Box p={2}>
                <Skeleton variant="rect" height={45} width={250} />
            </Box>
            <TableSkeleton />
        </>
    );
};

const GroupCountSkeleton = () => {
    return (
        <>
            <Box pl={1}>
                <Skeleton variant="rect" height={30} width={490} />
            </Box>
        </>
    )
}


const TableSkeleton = () => {
    const RowSkeleton = () => {
        return (<>
            <Grid item xs={3}>
                <Skeleton variant="rect" height={40} />
            </Grid>
            <Grid item xs={3}>
                <Skeleton variant="rect" height={40} />
            </Grid>
            <Grid item xs={3}>
                <Skeleton variant="rect" height={40} />
            </Grid>
            <Grid item xs={3}>
                <Skeleton variant="rect" height={40} />
            </Grid>
        </>)
    }

    return (
        <Box pb={1.5}>
            <Grid style={{ justifyContent: "center" }} container spacing={1}>
                <Grid container item xs={12} spacing={3}>
                    <Grid container item xs={12} alignItems="center" justify="flex-end" direction="row" style={{ display: "flex" }}>
                        <Skeleton variant="circle" height={40} width={40} />
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <RowSkeleton />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <RowSkeleton />
                </Grid>
                <Grid container item xs={12} spacing={3}>
                    <RowSkeleton />
                </Grid>
            </Grid>
        </Box>
    )
};

export { TableSkeleton, UserDetailsSkeleton, GroupSkeleton, GroupCountSkeleton }
