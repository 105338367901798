import React, { useEffect, useState } from 'react';
import { RouteComponentProps, withRouter, useHistory } from 'react-router-dom';
import { AuthComponentProps } from '../AuthProvider';
import { makeStyles, ButtonGroup, Button, Typography, Box } from '@material-ui/core';
import { msalRequest } from '../config';
import { instance as api, setAuthHeader } from '../api';
import { UserInfo } from '../Models/userInfo';
import { aadEndpoints } from '../config';
import { UserDetailsSkeleton } from '../Utilities/Skeletons';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        },
    },
    div: {
        flexGrow: 1
    },
    icon: {
        fontSize: 16,
    }
}));

interface Props extends RouteComponentProps<{ query: string }> {
    auth: AuthComponentProps;
}

const UserDetailsBar: React.FC<Props> = (props: Props) => {
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState<UserInfo>(new UserInfo());
    const query = props.location.hash ? encodeURIComponent(props.match.params.query + props.location.hash) : props.match.params.query
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const handleDetails = () => {
        history.push(`/user/${query}`)
    }
    const handleGroups = () => {
        history.push(`/user/${query}/groups`)
    }
    const handlePhoto = () => {
        history.push(`/user/${query}/photo`)
    }
    useEffect(() => {
        let isMounted = true;
        setIsLoading(true);
        const getStaffInfo = async (endpoint: string) => {
            const token = await props.auth.getAccessToken(msalRequest.scopes);
            setAuthHeader(token);
            await api.get<UserInfo>(`${endpoint}`)
                .then((resp) => {
                    if (isMounted) {
                        setUserInfo(resp.data);
                        setIsLoading(false);
                    }
                }).catch(err => console.log(err));
        }
        getStaffInfo(`GetUserDetails/${query}`);
        return () => {
            isMounted = false;
        };
    }, [query, props]);

    return (
        <>
            {isLoading ? <UserDetailsSkeleton /> :
                <>
                    <Box display="flex" p={2} pl={1.4}>
                        <Box pt={1.5} pr={1}>
                            {userInfo.accountEnabled ? <CheckCircleIcon className={classes.icon} style={{ color: "green" }} /> :
                            <CancelIcon color="error" className={classes.icon} />}
                        </Box>
                        <Box>
                            <Typography variant="h4">
                                {userInfo.displayName}
                            </Typography>
                        </Box>
                    </Box>
                    <div className={classes.root}>
                        <ButtonGroup orientation="horizontal" color="primary" variant="contained">
                            <Button color="primary" variant="contained" onClick={handleDetails}>Details</Button>
                            <Button color="primary" variant="contained" onClick={handleGroups}>Groups</Button>
                            <Button color="primary" variant="contained" onClick={handlePhoto}>Photo</Button>
                        </ButtonGroup>
                        <div className={classes.div} />
                        <ButtonGroup orientation="horizontal" color="primary" variant="contained">
                            {aadEndpoints.map((item, index) => (
                                <Button key={index} color="primary" variant="contained" href={`${item.link}${userInfo.msGraphObjectId}`} target="_blank" >{item.title}</Button>
                            ))}
                        </ButtonGroup>
                    </div>

                </>
            }
        </>);
}

export default withRouter(UserDetailsBar);
